@import url("https://fonts.googleapis.com/css2?family=Barlow&family=Playfair+Display&family=Playfair+Display+SC&family=Roboto+Condensed&display=swap");
@import "../public/assets/scss/media-queries.scss";

$breakpoints: (
  "mob": 320px,
  "xlMob": 560px,
  "tab": 768px,
  "lap": 1024px,
  "desk": 1400px,
);

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  background-color: #000;
  color: #fff;
}

a {
  text-decoration: none;
  padding: 0.5em 1em;
  color: #fff;
  transition: 0.2s;
  &:hover {
    color: #459ddd;
  }
}

.font-md {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.2rem;
}

.font-lg {
  font-size: 5rem;
}

.margin-bottom {
  margin-bottom: 5rem;
}

.spacer {
  height: 100px;
}

// music

.coming-soon {
  text-align: center;
  color: #bbb !important;
  font-family: "Barlow", sans-serif;
  font-size: 22px;
  letter-spacing: 10px;
  padding: 5%;
  animation-name: coming-soon;
  animation-fill-mode: forwards;
  animation-duration: 4s;
}

.music-page-container {
  width: 100vw;
  height: auto;
  animation-name: letters;
  animation-duration: 1.1s;
  transition: 200ms;
}

.nav {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  font-size: 0.7em;
  background-color: #b9b6a3;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: 6px;
  z-index: 99;
}

.bg-image {
  background-size: cover;
  background-position: 0;
  height: 100vh;
  background-image: url("../assets/img/AlbumCover.jpg");
  background-repeat: no-repeat;
  animation-name: fade-in;
  animation-duration: 4s;
  transition: 2s;
  overflow: hidden;

  @include media("<520px") {
    background-position: -10em 0;
  }
}

.subtitle {
  font-size: 3rem;
  color: #fff;
}

.video {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  padding: 2rem;
  background-color: #000;
}

iframe {
  max-width: 100%;
}

.bold {
  font-weight: bold;
}

a.anchor {
  visibility: hidden;
  display: block;
  position: relative;
  top: -40px;
  text-decoration: none;
}

.title {
  display: flex;
  justify-content: center;
  font-family: "Playfair Display SC", serif;
  font-size: 10vw;
  color: #fff;
}

.music {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  height: 40em;
  padding: 1em;
  margin: 0 auto;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  padding: 2rem;
}

.live {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  height: 30vh;
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  padding: 2rem;
}

.music-footer {
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 1rem;
}

.music-platform-icon {
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 1rem;
  transition: 200ms;
  &:hover {
    transform: scale(1.1);
    transition: 200ms;
  }
}

.itunes {
  background-image: url("../assets/img/itunes.jpg");
}

.amazon-music {
  background-image: url("../assets/img/amazon-music.jpg");
}

.spotify {
  background-image: url("../assets/img/spotify.jpg");
}

.get-in-touch {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// developer

.pad {
  height: 20vw;
}

.error-msg {
  padding-top: 2em;
  text-align: center;
}

.github-link {
  margin-top: 2em;
  font-size: 2rem;
  color: #fff;
  transition: 200ms;
  &:hover {
    transform: scale(1.1);
    color: #5ed3f3;
  }
  &:active {
    @extend :hover;
  }
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--container);
  font-family: "Roboto Condensed", sans-serif;
  background-image: url("../assets/img/shadow.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% -25em;
  margin-left: 0;
  margin-right: 0;
  @include media(">=500px") {
    background-position: 50% -40em;
  }
  @include media(">=616px") {
    background-position: 50% -50em;
  }
  @include media(">=tab") {
    background-position: 50% -50em;
  }
  @include media(">=950px") {
    background-position: 50% -70em;
  }
  @include media(">=1052px") {
    background-position: 50% -60em;
  }
  @include media(">=1350px") {
    background-position: 50% -70em;
  }
  @include media(">=1468px") {
    background-position: 50% -80em;
  }
  @include media(">=2182px") {
    background-position: 50% -80em;
  }
  @include media(">=2374px") {
    background-position: 50% -100em;
  }
  @include media(">=2800px") {
    background-position: 50% -110em;
  }
  @include media(">=3000px") {
    background-position: 50% -120em;
  }
}

.letter-container {
  height: 100vh;
  width: 100vw;
  background-color: #111;
  animation-name: container-disappear;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.letter {
  position: absolute;
  top: 0;
  left: 30%;
  overflow: hidden;
  color: #111;
  line-height: 70vh;
  height: 100vh;
  font-family: "Playfair Display SC", serif;
  font-size: 80vw;
  z-index: -99;
  // animation-name: letter-full;
  // animation-duration: 2s;

  // @include media("<tab") {
  //   animation-name: letter-mob;
  // }
  // @include media('>1468px') {
  //   animation-name: letter-large;
  // }
  &.letter-2 {
    animation-delay: 300ms;
  }
  &.letter-3 {
    animation-delay: 600ms;
  }
  &.letter-4 {
    animation-delay: 900ms;
  }
  &.letter-5 {
    animation-delay: 1200ms;
  }
  &.letter-6 {
    animation-delay: 1500ms;
  }
  &.letter-7 {
    animation-delay: 1800ms;
  }
  &.letter-8 {
    animation-delay: 2100ms;
  }
  &.letter-9 {
    animation-delay: 2400ms;
  }
  &.letter-10 {
    animation-delay: 2700ms;
  }
  &.letter-11 {
    animation-delay: 3000ms;
  }
}

// .gradient {
//   position: absolute;
//   bottom: 0;
//   margin-left: 0;
//   margin-right: 0;
//   height: 0;
//   width: 100vw;
//   background-color: rgba(143, 181, 192, 1);
//   animation-name: pull-down;
//   animation-duration: 1.5s;
//   animation-delay: 4000ms;
//   animation-fill-mode: forwards;
// }

.info-page-one {
  height: 100vh;
  overflow: hidden;
  @include media(">=mob") {
    height: 25vh;
  }
  @include media(">=tab") {
    height: 25vh;
  }
  @include media(">=950px") {
    height: 25vh;
  }
  @include media(">=1468px") {
    height: 70vh;
  }
  @include media(">=1670px") {
    height: 90vh;
  }
}

.info-page-two {
  height: auto;
  overflow: hidden;
}

.big-top-margin {
  margin-top: 2em;
}

.intro {
  display: flex;
  justify-content: center;
}

.small {
  font-size: 3vw;
}

.medium-font {
  font-size: 5vw;
}

.large-font {
  font-size: 10vw;
}

.reveal {
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}

.flip-one {
  position: absolute;
  top: 100px;
  left: 0;
  right: 5em;
  color: #fff;
  @include media(">tab") {
    top: 40px;
  }
}

.end-name {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  color: #254bdd;
  @include media(">tab") {
    top: 40px;
  }
}

.fade-in-after-flip {
  position: absolute;
  top: 100px;
  left: 0.8em;
  right: 0;
  color: white;
  opacity: 0;
  @include media(">tab") {
    top: 40px;
  }
}

.contact-fade {
  display: flex;
  flex-direction: row;
  font-family: "Playfair Display SC", serif;
  font-size: 1.8vw;
  text-align: center;
  padding: 1rem 2rem;
  justify-content: space-around;
  @include media("<tab") {
    flex-direction: column;
    font-size: 4vw;
  }
  @include media(">1920px") {
    flex-direction: column;
    font-size: 1.8vw;
  }
  animation-name: fade-in;
  animation-duration: 3s;
}

.top-level {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 5% 2% 5%;
  color: #459ddd;
  @include media(">=mob") {
    font-size: 2em;
    margin: 0 5% 20% 5%;
  }
  @include media(">950px") {
    margin: 0 5% 10% 5%;
  }
  @include media(">=tab") {
    font-size: 2em;
    margin: 0 5% 2% 5%;
  }
  @include media(">=1468px") {
    font-size: 1em;
    margin: 0 5% 2% 5%;
  }
}

.work {
  margin-top: 5em;
  margin-bottom: 0;
  @include media(">=mob") {
    margin-top: 6em;
  }
  @include media(">=tab") {
    margin-top: 6em;
  }
  @include media(">=950px") {
    margin-top: 9em;
  }
  @include media(">1052px") {
    margin-top: 4em;
  }
  @include media(">1468px") {
    margin-top: 0;
  }
  @include media(">2120px") {
    margin-top: 3em;
  }
  @include media(">2556px") {
    margin-top: 0;
  }
  @include media(">2882px") {
    margin-top: 3em;
  }
}

.thumbnail-teammateme {
  margin: 1em;
  width: 20vw;
  height: 2em;
  transition: 200ms;
  background-image: url("../assets/img/teammateme.png");
  background-size: cover;
  background-position: 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
  @include media("<lap") {
    background-position: 100%;
    background-size: contain;
    width: 50vw;
    height: 4em;
  }
  &:hover {
    background-position: 100% -0.8em;
  }
}

.thumbnail-q3 {
  margin: 1em;
  width: 20vw;
  height: 2em;
  transition: 200ms;
  background-image: url("../assets/img/quest-3.jpg");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 10px;
  @include media("<lap") {
    width: 50vw;
    height: 4em;
  }
  &:hover {
    background-position: 50% -0.8em;
  }
}

.pop-up-q3 {
  position: absolute;
  bottom: 3.4em;
  text-align: center;
  font-size: 0.3em;
  z-index: -99;
  transition: 200ms;
  @include media("<lap") {
    z-index: 99;
    bottom: 0.4em;
    left: 20%;
    right: 20%;
    font-size: 0.5em;
  }
  &:hover {
    z-index: 99;
  }
}

.thumbnail-ponderware {
  margin: 1em;
  width: 2em;
  height: 3em;
  transition: 200ms;
  background-image: url("../assets/img/mooncat-head.jpg");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 10px;
  @include media("<lap") {
    width: 4em;
    height: 5em;
  }
  &:hover {
    background-position: 50% -0.8em;
  }
}

.pop-up-ponderware {
  @extend .pop-up-q3;
}

.thumbnail-can-render {
  margin: 1em;
  width: 30vw;
  height: 3em;
  transition: 200ms;
  background-image: url("../assets/img/can-0-render.jpg");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 10px;
  @include media("<lap") {
    width: 50vw;
    height: 4em;
  }
  &:hover {
    background-position: 50% -1.2em;
  }
}

.pop-up-can-render {
  @extend .pop-up-q3;
}

.thumbnail-gravball {
  margin: 1em;
  width: 3em;
  height: 4em;
  transition: 200ms;
  background-image: url("../assets/img/mooncat-head.jpg");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.thumbnail-ecommerce {
  margin: 1em;
  width: 30vw;
  height: 3em;
  transition: 200ms;
  background-image: url("../assets/img/ecommerce-demo.jpg");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 10px;
  @include media("<lap") {
    width: 30vw;
    height: 5em;
  }
  &:hover {
    background-position: 50% -0.8em;
  }
}

.pop-up-ecommerce {
  @extend .pop-up-q3;
}

.resume {
  margin-top: 2em;
  font-size: 0.3em;
  color: #fff;
  transition: 200ms;
  &:hover {
    transform: scale(1.1);
    color: #5ed3f3;
  }
  &:active {
    @extend :hover;
  }
}

.cover-letter {
  @extend .resume;
}

.footer {
  display: flex;
  justify-content: center;
  text-align: center;
  position: sticky;
  top: 100vh;
  padding-bottom: 1em;
  margin: 1em;
  color: #fff;
}

.social {
  color: #fff;
  transition: 200ms;
  padding: 0.5em 1em;
  &:hover {
    transform: scale(1.6);
    color: #459ddd;
  }
}

// resume

:root {
  --body: #000;
  --container: #111;
  --section: pink;
  --text: #fff;
  --contact: #459ddd;
  --name: #459ddd;
  --contactFontSize: 5rem;
  --mob: 320px;
  --xlMob: 560px;
  --tab: 768px;
  --lap: 1024px;
  --desk: 1400px;
}

i {
  padding: 0.6rem;
}

img {
  padding: 0.6rem 0;
}

li {
  list-style-type: none;
}

h3 {
  font-size: 18pt;
}

.resume-body {
  background-color: var(--container);
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1em;
  color: var(--text);
  margin: 0 5%;
  @include media(">1920px") {
    margin-left: 20%;
    margin-right: 20%;
  }
}

.name {
  font-family: "Playfair Display SC", serif;
  font-size: 11vw;
  text-align: center;
  padding: 1rem;
  background: -webkit-linear-gradient(#64beff, var(--name));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation-name: fade-in;
  animation-duration: 3s;
  /* color:var(--name); */
}

.name-link {
  padding: 0;
}

.summary {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14pt;
  opacity: 0;
  padding: 1rem 1rem;
  text-align: justify;
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  margin-left: 5%;
  margin-right: 5%;
  @include media(">2470px") {
    text-align: center;
  }
  animation-name: fade-in;
  animation-duration: 3s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.contact {
  display: flex;
  flex-direction: row;
  font-family: "Playfair Display SC", serif;
  font-size: 1.8vw;
  text-align: center;
  padding: 1rem 2rem;
  justify-content: space-around;
  @include media("<tab") {
    flex-direction: column;
    font-size: 4vw;
  }
  @include media(">1920px") {
    flex-direction: column;
    font-size: 1.8vw;
  }
}

.resume-link {
  color: var(--contact);
  text-decoration: none;
  transition: 200ms;
  &:hover {
    color: #fff;
    transform: scale(1.1);
  }
}

.phone {
  color: var(--contact);
  vertical-align: middle;
  padding: 0.5em 1em;
}

.content-container {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
}

.detail-section {
  margin: 0 1rem 0 1rem;
  word-wrap: normal;
}

.detail-section-small {
  font-size: 1em;
  margin: 0 1rem 0 1rem;
  word-wrap: normal;
}

.subtext {
  // font-size: 26pt;
  padding: 0 1rem 1rem 1rem;
  color: #fff;
}

.subtext-small {
  font-size: 11pt;
  padding: 0 1rem;
}

.resume-title {
  color: #fff;
  font-size: 15pt;
  font-weight: bold;
}

.resume-title-small {
  color: #fff;
  font-size: 12pt;
  font-weight: bold;
}

.icon-container {
  display: flex;
  flex-wrap: wrap;
  // justify-content:space-around;
}

.icon {
  display: flex;
  flex-direction: column;
  width: 20%;
  justify-content: center;
  text-align: center;
  padding: 1em;
  transition: 200ms;
  &:hover {
    transform: scale(1.5);
    z-index: 999;
  }
  &:active {
    @extend :hover;
  }
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

// resume icons

.react {
  color: #5ed3f3;
}

.vue {
  color: #3fb27f;
}

.html5 {
  color: #dd4b25;
}

.css3 {
  color: #254bdd;
}

.sass {
  color: #c76494;
}

.javascript {
  color: #e9d44d;
}

.git {
  color: #e94e31;
}

.docker {
  color: #2392e6;
}

.nodejs {
  color: #7fc728;
}

.npm {
  color: #c53635;
}

.solidity {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: 16px;
}

.mongodb {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: 16px;
}

.sql {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: 16px;
}

.tableau {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: 16px;
}

.ejs {
  color: #b4ca65;
  font-size: 11.5pt;
  padding: 0.6rem;
}

.developer-name {
  font-family: "Playfair Display SC", serif;
  font-size: 110vh;
  color: #fff;
  text-align: center;
  padding: 1rem;
  animation-name: slide-out;
  animation-duration: 10s;
  animation-delay: 1s;
  z-index: 1000;
}

.big-title {
  color: var(--contact);
  font-size: 16pt;
  padding: 0;
  margin: 0;
}

.udemy-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 0.7em;
  > div {
    display: flex;
    padding: 0.2em 0.5em;
    margin: 0.5em;
    width: 80px;
    justify-content: center;
    border: 1px solid #999;
    border-radius: 10px;
    background-color: rgba(99, 99, 99, 0.5);
    align-items: center;
    text-align: center;
    transition: 200ms;
    &:hover {
      background-color: #fff;
      color: #111;
      transform: scale(1.5);
      z-index: 999;
    }
    &:active {
      @extend :hover;
    }
  }
  @include media("<tab") {
    justify-content: center;
  }
}

.column > div {
  padding: 0.5rem;
}

.active.scroll-up {
  animation: scroll-up 500ms;
  animation-fill-mode: forwards;
}
.active.scroll-up-small {
  animation: scroll-up-small 500ms;
  animation-fill-mode: forwards;
}

.active.fade-in {
  animation: fade-in 2s;
  animation-fill-mode: forwards;
}

.active.flip-one {
  animation: rotate180;
  animation-duration: 200ms;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  transform: rotate(-180deg);
}

.active.fade-in-after-flip {
  animation: fade-in;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-delay: 2s;
  opacity: 0;
}

.active.end-name {
  animation-name: disappear;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-delay: 1.6s;
}

.letter-summary {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14pt;
  opacity: 0;
  padding: 1rem 1rem;
  text-align: justify;
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  margin-left: 5%;
  margin-right: 5%;
  @include media(">2040px") {
    margin-left: 20%;
    margin-right: 20%;
  }
  animation-name: fade-in;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.link {
  color: #5ed3f3;
  font-size: 1em;
  padding: 0;
  transition: 200ms;
  &:hover {
    color: #a9ecff;
  }
}

// animations

@keyframes bg-fade-in {
  from {
    background-image: none;
  }
  to {
    background-image: url("../assets/img/shadow.jpeg");
  }
}

@keyframes coming-soon {
  from {
    color: #b9b6a3;
    opacity: 0;
  }
  to {
    color: rgb(155, 122, 62);
    opacity: 1;
  }
}

@keyframes container-disappear {
  from {
    height: 100vh;
  }
  to {
    height: 0;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rotate180 {
  0% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
    color: #111;
  }
}

@keyframes pull-down {
  0% {
    // opacity: 0;
    height: 0;
  }
  70% {
    // opacity: 1;
    height: 10%;
  }
  100% {
    // opacity: 0;
    height: 0;
  }
}

@keyframes scroll-up {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scroll-up-small {
  from {
    transform: translateY(40px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes letter-mob {
  0% {
    color: #111;
    transform: translateX(200px);
  }
  5% {
    color: #459ddd;
    z-index: 100;
  }
  20% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-400px);
  }
  100% {
    color: #111;
    opacity: 0;
    z-index: -99;
  }
}

@keyframes letter-full {
  0% {
    color: #111;
    transform: translateX(400px);
  }
  5% {
    color: #459ddd;
    z-index: 100;
  }
  20% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-800px);
  }
  100% {
    color: #111;
    opacity: 0;
    z-index: -99;
  }
}

@keyframes letter-large {
  0% {
    color: #111;
    transform: translateX(1000px);
  }
  5% {
    color: #459ddd;
    z-index: 100;
  }
  20% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-1000px);
  }
  100% {
    color: #111;
    opacity: 0;
    z-index: -99;
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(450vw);
  }
  100% {
    transform: translateX(-450vw);
  }
}
